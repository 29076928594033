
header {
    padding: $paddingNormal 0;
    height: 80px;
    border: 1px solid #E9EBED;
}

header .logo img {
    height: 60px;
}

header .cover-img {
    object-fit: contain;
}

header .user p, header .signout p {
    font-size: $fontRegular;
}

header .signout {
    margin-left: $paddingMedium;
    cursor: pointer;    
}

header .signout p {
    color: $blue;
}

header .signout p:hover {
    color: $blue;
}