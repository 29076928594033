
.admin .form-filter-products input[type="text"]{
    min-width: 60%;
}

.admin .form-filter-products {
    .search-input {
        padding-right: 8px;

        .input {
            width: 100%;
        }
    }
}

.product-b {
    background: white;
    margin: 0 40px;
}

.product-images {
    padding: 15px 25px;
    width: 45%;

    .principal-img {
        position: relative;
        //max-height: 350px;
        //border: 1px solid rgba(0, 0, 0, 0.2);
        //border-top: 6px solid $yellow;
        img {
            //Quitar
            //height: 360px;
            width: 100%;
            object-fit: cover;
            object-position: center;
            margin-left: 0 !important;
        }

        .rd-parallax-gallery {
            /*opacity: 0;
            transition: .3s all ease;*/
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: 300ms all ease;
            //visibility: hidden;
            opacity: 0;
            //transform: translate(-50%, 100%);

            .top-text {
                cursor: pointer;
            }

            .top-text i {
                color: white;
                font-size: 2.3em;
                cursor: pointer;
            }
        }

        &:hover> .rd-parallax-gallery {
            background: linear-gradient(to bottom, transparent 5%, rgba(black, 0.8));
            //visibility: visible;
            opacity: 1;
        }
    }


    .gallery {
        display: grid !important;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 0.5rem;
    }

    .gallery-img img {
        height: 100px;
        object-fit: cover;
        object-position: center;
        width: 100%;
    }

    .gallery-img {
        position: relative;
        border: 1px solid rgba(0, 0, 0, 0.2);

        .rd-parallax-gallery {
            /*opacity: 0;
            transition: .3s all ease;*/
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: 300ms all ease;
            //visibility: hidden;
            opacity: 0;
            //transform: translate(-50%, 100%);

            .top-text {
                cursor: pointer;
            }

            .top-text i {
                color: white;
                font-size: 2.3em;
                cursor: pointer;
            }
        }

        &:hover> .rd-parallax-gallery {
            background: linear-gradient(to bottom, transparent 5%, rgba(black, 0.8));
            //visibility: visible;
            opacity: 1;
        }
    }
}

.product-info {
    .container {
        width: 85%;

        button {
            width: 40%;
        }
    }
}

.color-text-banner .swatches-picker div {
    display: block;
}