

.modal {    
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: auto;
    position: fixed;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
}

.modal-content {
    width: 80%;    
    margin: auto;
    position: relative;
    background-color: $white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;    
}

@-webkit-keyframes animatetop {
    from {top:-300px; opacity:0} 
    to {top:0; opacity:1}
}

@keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
}

.close-modal {
    color: $red;    
    font-size: 1.5em;
    margin-right: $paddingMedium;
}

.modal-header {
    padding: $paddingMedium 0;
    background-color: $aqua;
}

.modal-body-container {
    align-items: center;
}

.modal-body .user-data{    
    width: 95%;
    justify-content: center;
}

.btn-container-login .btn {
    flex-grow: 1;
}

#modal-login .modal-content {
    width: 40%;
}

#modal-order .cart-container .container {
    width: 95%;
}

#modal-order .cart-container .table-image img {
    max-height: 40px;
}

#modal-order .modal-content
.btn-container-accept {
    min-width: 60%;
}

#modal-order .modal-content
.btn-container-accept button {
    flex-grow: 1;
}

#modal-order .modal-content
.btn-container-cancel button {
    flex-grow: 1;
}

#modal-order .table-total-summary table {
    max-width: 100%;
}

#modal-order .table-client .table-clientInfo,
#modal-order .table-client .table-clientAddress {
    padding: $paddingMedium $paddingNormal;    
}

#modal-add-user .modal-content {
    width: 35%;

    .modal-header {
        background: darkslategray;
    }

    .btn-container-login {
        .btn-aqua {
            border-radius: 0;
        }
    }

    .modal-body {
        label {
            color: #9C9C9C;
        }
    }

    #close-modal-delete {
        width: 50%; 
        padding: $paddingMedium;
        border-radius: 0;
        //border-radius: 0 0 4px 0;
    }
}

#modal-add-user .users-data {
    width: 90%;
}

#modal_delete_costo .modal-content {
    width: 30%;
}

.modal-password {
    .modal-header {
        background: darkslategray;
    }

    .modal-content {
        width: 40%;

        .users-data {
            width: 90%;
        }

        .fa-times {
            color: azure;
            cursor: pointer;
        }

        .btn-aqua {
            border-radius: 0;
        }

        button {
            width: 50%; 
            padding: $paddingMedium;
            border-radius: 0;
            //border-radius: 0 0 4px 0;
        }
    }
}

#detail-question-modal .modal-content{
    width: 30%;
}

#detail-message-modal .modal-content{
    width: 25%;
}

#modal_delete_category,
#modal_delete_price,
#modal_delete_agencia {
    .modal-content {
        width: 25%;
    }
}

#modal-add-tc .modal-content {
    width: 25%;

    .modal-header {
        background-color: darkslategray;
    }

    #close-modal-delete {
        width: 40%;
        padding: $paddingMedium;
        border-radius: 0;
    }
}

#modal-end-conversation .modal-content {
    background-color: $black;
    width: 30%;
    margin: auto;
    position: relative;
    align-self: flex-start;

    #btn-deleteChat { 
        width: 50%; 
        padding: $paddingMedium;
        border-radius: 0;
        //border-radius: 0 0 0 4px;
    }

    #close-modal-delete {
        width: 50%; 
        padding: $paddingMedium;
        border-radius: 0;
        //border-radius: 0 0 4px 0;
    }
}

#modal-reservation-detail .modal-content {
    background-color: $white;
    width: 50%;
    margin: auto;
    position: relative;
    align-self: flex-start;

    .modal-head {
        background: /*darkslategray*/#272727;

        .close-modal-reservation a{
            padding-right: 12px;
            color: white;
            padding-top: 8px;
            font-weight: 600;
            cursor: pointer;
        }
    }

    .reservation-detail-inputs {
        padding: 0 10px;

        .change-status {
            .input-status {
                border: 2px solid #7da2ab;
                padding: 8px;
                color: #272727;
                width: 100%;
            }

            .save, .cancel {
                width: 80%;
            }

            .btn-save {
                width: 90%;
                color: white;
                border-radius: 4px;
                cursor: pointer;
                font-size: 1em;
                font-weight: 600;
            }
        }

        .first {
            border-bottom: 1px solid gray;

            .up {
                .up-data {
                    .left {
                        width: 50%;
                    }
                }
            }

            .up h4 {
                color: #2d7d90;
            }

            .down {
                padding-left: 40px;

                .down-data {
                    .left {
                        width: 40%;
                    }
                }
            }

            .down h4 {
                color: #2d7d90;
            }
        }

        .middle {
            border-bottom: 1px solid gray;

            .left {
                .middle-data .left {
                    width: 70%;
                }

                .middle-data .right {
                    padding-left: 0;
                }
            }

            .right {
                padding-left: 40px;

                .middle-data .left {
                    width: 65%;
                }

                .middle-data .right {
                    padding-left: 0;
                }

                .whats-provider i {
                    color: green;
                    font-size: 2.2em;
                }

                .provider-status label{
                    padding-right: 10px;
                }
            }
        }

        .middle h4 {
            color: #2d7d90;
        }
    }
    
    .reservation-detail-data input {
        padding: 4px 12px;
        font-size: 14px;
        border: 1px solid #7da2ab;
        border-radius: .25rem;
    }

    #btn-deleteChat { 
        width: 50%; 
        padding: $paddingMedium;
        border-radius: 0;
        //border-radius: 0 0 0 4px;
    }

    #close-modal-delete {
        width: 50%; 
        padding: $paddingMedium;
        border-radius: 0;
        //border-radius: 0 0 4px 0;
    }
}

#modal-add-hotel .modal-content, #modal-add-agencia .modal-content {
    background-color: $white;
    width: 30%;
    margin: auto;
    position: relative;
    align-self: flex-start;

    .modal-head {
        background: darkslategray;
    }

    .hotel-inputs {
        padding: 0 10px;

        .hotel-status label {
            padding-right: 10px;
        }
    }
    
    .hotel-data input {
        padding: 4px 12px;
        font-size: 14px;
        border: 1px solid #7da2ab;
        border-radius: .25rem;
    }

    #btn-deleteChat { 
        width: 50%; 
        padding: $paddingMedium;
        border-radius: 0;
        //border-radius: 0 0 0 4px;
    }

    #close-modal-delete {
        width: 50%; 
        padding: $paddingMedium;
        border-radius: 0;
        //border-radius: 0 0 4px 0;
    }
}

#modal-add-proveedor .modal-content {
    background-color: $white;
    width: 30%;
    margin: auto;
    position: relative;
    align-self: flex-start;

    .modal-head {
        background: darkslategray;
    }

    .provider-data input {
        padding: 4px 12px;
        font-size: 14px;
        border: 1px solid #7da2ab;
        border-radius: .25rem;
    }

    .provider-inputs {
        padding: 0 10px;

        .provider-status label {
            padding-right: 10px;
        }
    }

    #btn-deleteChat { 
        width: 50%; 
        padding: $paddingMedium;
        border-radius: 0;
        //border-radius: 0 0 0 4px;
    }

    #close-modal-delete {
        width: 50%; 
        padding: $paddingMedium;
        border-radius: 0;
        //border-radius: 0 0 4px 0;
    }
}

#modal-horarios .modal-content {
    width: 40%;
    #btn-yes { 
        width: 50%; 
        padding: $paddingMedium;
        border-radius: 0;
    }

    #btn-no {
        width: 50%; 
        padding: $paddingMedium;
        border-radius: 0;
    }

    .modal-head .horarios {
        background: darkslategray;
    }
}

#modal_provider_confirmation .modal-content-confirmation {  
    width: 45%;    
    margin: auto;
    position: relative;
    background-color: $white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;    
}

//Banner
.close-banner {
  position: absolute;
  right: 0;
  top: 5px;
  width: 30px;
  height: 30px;
  display: block;
  overflow: hidden;
}

.close-banner i {
  color: white;
  font-size: 1.8em;
  cursor: pointer;
}

.info-product {
  position: relative;
  margin-right: 36px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 4px;
  box-shadow: 0px 0px 20px 6px #9E9E9E;
}

.banner-b {
  /*position: absolute;
  bottom: 45px;*/
  height: 100vh;
}

.text-banner {
  /*margin-bottom: 210px;*/
  height: 45vh;
}

.text-banner h1 {
  font-size: 2em;
  font-family: 'Della Respira';
}

.btn-banner {
  box-shadow: 0 0 16px 4px;
  width: 25%;
  border-radius: 4px;
  padding: 10px 24px;
  font-size: 1.2em;
  font-family: 'Raleway';
}

.banner-content {
  width: 55%;
  background: transparent;
  box-shadow: none;
}

.modal-body-banner {
  position: relative;
  height: 450px;
}

.color-text-banner {
    .slider-picker {
        align-items: center;
        width: 50%;
        flex-direction: column;
    }

    .swatches-picker div {
        display: block;
    }
}

@media screen and (max-width: 1200px) {
     #modal-add-user .modal-content {
        width: 60%;
    }

    #modal-add-user .users-data {
        width: 96%;
    }
}


@media screen and (max-width: 992px) {

    #modal-login .modal-content {
        width: 50%;
    }   
}

@media screen and (max-width: 768px) {

    #modal-login .modal-content {
        width: 65%;
    }
}

@media screen and (max-width: 768px) {

    #modal-login .modal-content {
        width: 80%;
    }

}

#modal-precios {
	.modal-content {
		width: 70%;
		.modal-head {
			background: darkslategray;
			position: relative;
			.modal-close {
				position: absolute;
				top: 20px;
				right: 20px;
				justify-content: flex-end;
			}
		}
		.modal-body {
			display: flex;
			justify-content: center;
		}
		table {
			max-width: 100%;
			tr {
				td {
					border: 1px solid #E9EBED;
					padding: 5px 0px;
				}
			}
		}
		.input-text {
			max-width: 80%;
		}
	}
}
