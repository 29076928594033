.admin .container-add-product {
    background-color: $white;
}

.admin .container-add-product .left {
    .product-image-four, .product-image-preview {
        .product-img {
            background: black;
            position: relative;
            border-radius: 4px;

            .rd-parallax-gallery {
                /*opacity: 0;
                transition: .3s all ease;*/
                border-radius: 4px;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: 300ms all ease;
                //visibility: hidden;
                opacity: 0;
                //transform: translate(-50%, 100%);

                .top-text i {
                    color: white;
                    font-size: 2.3em;
                    cursor: pointer;
                }
            }

            &:hover> .rd-parallax-gallery {
                background: linear-gradient(to bottom, transparent 5%, rgba(black, 0.8));
                //visibility: visible;
                opacity: 1;
                cursor: pointer;
            }
        }
    }

    .product-image-four .product-img .rd-parallax-gallery .top-text i {
        font-size: 1.3em;
    }

    .product-image-four .product-img img {
        height: 93.10px;
        width: 100%;
    }
}

.admin .container-add-product .right {
    //Tabs
    .htmlForm-add-product .tabs{ 
        .steps {
            padding: 4px 0;
            .step-circle {
                background: gray;
                padding: 5px 11px;;
                border-radius: 50%;
                margin-right: 8px;
                cursor: pointer;
            }

            .step-active {
                background: $aqua;
                padding: 5px 11px;;
                border-radius: 50%;
                margin-right: 8px;
                cursor: pointer;
            }

            &:hover {
                .step-circle {
                    background-color: $aqua;
                    cursor: pointer;
                }

                .step-text h3 {
                    color: $aqua;
                    cursor: pointer;
                }
            }
        }
    }

    .step1 {
        /*.link #product_link {
            width: 90%;
        } */

        .space input {
            width: 90%;
        }
        
        .limit {
            width: 40%;

            #product_limit {
                width: 90%;
            }
        }      
        
        .price {
            //width: 42%;
            width: 100%;
        }
    }

    .options {
        background-color: #eee;
        width: 90%;
        border-radius: 5px;
    }

    .add-options {
        width: 90%;

        .input-text {
            width: 50%;
        }
    }

    .step2 textarea, .step3 textarea, .step4 textarea {
        // height: 200px;
    }

    .file {
        width: 50%;

        label {
            font-weight: $weightRegular;
            color: white;
        }
    }

    .file-slider {
        width: 60%;

        label {
            font-weight: $weightRegular;
            color: white;
        }
    }

    .pdf {
        i {
            font-size: 1.1em;
            color: red;
        }
    }
}

.admin .container-add-product .left p {
    font-weight: 600;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.9);
}

.admin .container-add-product .right label {
    font-weight: 600;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.9);
}

.section-selects .input-select {
    width: 90%;
    border-radius: 4px;
}

.container .container-add-product {
    box-shadow: 0 0 1px rgba(0,0,0,0.5);
    border-radius: 2px;
}

.admin .container-add-product .right {
    min-width: 60%;
}

.admin .product-image-preview {
    width: 480px;
}

.admin .product-image-four {
    width: 480px;
    display: grid !important;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0.5rem;
}

.admin #product-image-esp, #product-image-ing, #product-image-pt {
    cursor: pointer;
    border-radius: 4px;
    height: 204px;
}

.admin #upload-product-image-input {
    display: none;
}

.admin .prices {
    width: 75%;
}

.admin .price-div {
    font-weight: $weightBlack;
    font-size: $fontRegular;
    .input-text {
        max-width: 140px;
    }
}

.admin .container-add-product input[name="product-price"] {
    font-weight: $weightBlack;
    color: $aqua;    
}

.form-add-product {
    width: 100%;
}

.images-map {
    display: flex;
    width: 100%;
    overflow: auto;
}

.prices-calendar {
	padding: 8px 12px;
    font-size: 14px;
    border: 1px solid #7da2ab;
	border-radius: .25rem;
	width: 20%;
}

.prices-table,
.bloqueo {
    background: #eee;
    width: 90%;
    border-radius: 5px;

    .input-text {
        width: 80%;
    }
}