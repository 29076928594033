
.admin .workspace .summary-orders-table .table-row {
    cursor: pointer;
}

.admin .workspace .summary-orders-table .table-row:hover {
    background-color: $lightGray;    
}

/*.admin .pagination .page {
    background-color: $lightGray;
    border: 1px solid $lightGray;
}

.admin .pagination .page:hover {
    background-color: $aqua;
    border: 1px solid $white;
}*/