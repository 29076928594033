/* ==========================================================================
    Products
========================================================================== */

.products-container {
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    grid-gap: $paddingMedium;
}

.product {    
    min-height: 340px;
    margin-bottom: $paddingMedium;    
}

.product:hover {
    border: $boder-focus;
}

.product .image {
    justify-content: center;
}

.product .image img {
    height: 220px;
}

.product .description {
    background-color: $lightGray;
    flex-grow: 1;
    padding: $paddingMedium 0 0 0;
}

.product .description .name {
    font-size: $fontSmall;
    font-weight: $weightBlack;
    letter-spacing: 0.5px;
    min-height: 40px;
}

.product .description .price {
    font-weight: $weightBlack;
    font-size: $fontLarge;
}

.product .btn {
    flex-grow: 1;
}

.products-main .products-container {
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    grid-gap: $paddingMedium;
}

@media screen and (max-width: 1200px) {

    .products-container {        
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        grid-gap: $paddingMedium $paddingNormal;
    } 

}

@media screen and (max-width: 992px) {

    .products-container {        
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));        
    }    

}

@media screen and (max-width: 768px) {
    
    .products-container {
        display: flex !important;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .product {
        min-height: 340px;
        margin-bottom: $paddingMedium;
    }    

}
