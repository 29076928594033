
/*.pagination .page {       
    //border: 4px solid $white;
    transition: all 0.1s;
}*/

/*.pagination .page:hover {
    background-color: $aqua;  
    border: 4px solid $aqua;  
    color: $white;
}*/

.pagination .page-link {
    padding: 8px 13px;
    color: black;
    float: left;
    text-decoration: none;
    transition: background-color .3s;
    border: 1px solid #9c9c9c99;
    margin: 0 4px;
    border-radius: 20px;
}

.page a:hover:not(.active) {
    color: $green;
    border-color: $green;
    cursor: pointer;
}

.pagination .current-page a {
    border-color: $blue;
    color: $blue;
}

/*.pagination .current-page {
    border: 4px solid $aqua;
}

.pagination .current-page a {
    background: $aqua;
    color: $white;
}*/