@charset "UTF-8";
/*
    INFORMACIÓN
	- File:           trejocode.scss
	- Versión:        1.1.4
	- Licence:        MIT
	- Author:         DWIT - TrejoCode
    - Update:         19/10/2018
    - Client:         Elfos Gourmet
*/
/* PROPERTIES
   =============================

	1. = COLORS
	2. = FONT FAMILY
    3. = FONT SIZE
    4. = FONT WEIGHT
	5. = PADDINGS
    6. = BORDERS

*/
/*    COLORS    */
/*    FONT FAMILIES    */
/*    FONT SIZES    */
/*    FONT WEIGHTS 

        - Based on 'Montserrat'

    */
/*    PADDINGS, MARGINS

        This properties also applies for 'Margins'

    */
/*    BORDER    */
/* RESET
   =============================

   - These properties able the cross compatibility for browsers and removes default properties from browsers.

*/
* {
  padding: 0;
  margin: 0;
  transition: all 0.5s ease; }

::selection {
  background: #2d7d90;
  color: #FFFFFF; }

html {
  box-sizing: border-box; }

body {
  overflow-x: hidden;
  font-family: "Roboto", sans-serif;
  background-color: #FFFFFF; }

button, input, select, textarea, option {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none; }

table {
  border-spacing: 0; }

div, header, footer, section, article, aside, nav, form, table {
  width: 100%; }

ul, ol {
  list-style: none; }

a {
  text-decoration: none;
  color: inherit; }

img {
  max-width: 100%;
  height: auto;
  object-fit: cover; }

h1, h2, h3, h4, h5, h6 {
  color: #FFFFFF; }

h1 {
  font-size: 1.8em;
  font-weight: 700; }

h2 {
  font-size: 1.6em;
  font-weight: 700; }

h3 {
  font-size: 1.4em;
  font-weight: 700; }

h4 {
  font-size: 1.2em;
  font-weight: 500; }

h5 {
  font-size: 0.93em;
  font-weight: 500; }

h6 {
  font-size: 1.4em;
  font-weight: 400; }

p {
  font-size: 0.93em;
  color: #9C9C9C;
  line-height: 24px; }

/* CLASES
   =============================

	1. = FLEX LAYOUT
	2. = ALIGNMENT
    3. = WHITE SPACES
    4. = FORMS
    5. = BUTTONS
    6. = DECORATORS & COLORS

*/
/*    FLEX LAYOUT

    - This stylesheet is based on flexbox.
    - W3 CSS Flexible Box Layout Module:
       https://www.w3.org/TR/css-flexbox-1
    
*/
.flex {
  display: flex; }

.flex div, .flex img, .flex nav, .flex form {
  display: flex; }

.container {
  width: 90%; }

.responsive-img {
  display: flex;
  justify-content: center;
  align-items: center; }

.row {
  flex-direction: row; }

.row-responsive {
  flex-direction: row; }

.column {
  flex-direction: column; }

.auto {
  width: auto; }

.grid {
  display: grid !important; }

.gutters {
  margin: 0 auto; }

.gutters > div {
  margin: 12px; }

.gutters > div:first-of-type {
  margin-left: 0;
  color: #272727; }

.gutters > div:last-of-type {
  margin-right: 0; }

.wrap {
  flex-wrap: wrap; }

/*    ALIGNMENT    */
.justify-center {
  justify-content: center; }

.justify-start {
  justify-content: flex-start; }

.justify-end {
  justify-content: flex-end; }

.justify-between {
  justify-content: space-between; }

.justify-arround {
  justify-content: space-around; }

.align-center {
  align-items: center; }

.align-start {
  align-items: flex-start; }

.align-end {
  align-items: flex-end; }

/*    TEXT ALIGNMENT    */
.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

.text-right {
  text-align: right; }

/*    WHITE SPACES    */
.white-space-8 {
  height: 8px; }

.white-space-16 {
  height: 16px; }

.white-space-24 {
  height: 24px; }

.white-space-32 {
  height: 32px; }

.white-space-48 {
  height: 48px; }

.white-space-64 {
  height: 64px; }

/*    FORMS     */
label {
  color: #9C9C9C; }

.label-error {
  color: #E74C3C; }

/*.input {
    color: $darkGray;
    font-size: $fontMini;
    font-family: $Roboto;    
    font-weight: $weightMedium;    
}*/
.input {
  padding: 4px 12px;
  font-size: 14px;
  border: 1px solid #7da2ab;
  border-radius: .25rem; }

.input-search {
  min-height: 45px;
  border: 2px solid #E9EBED;
  border-radius: 45px 0 0 45px;
  padding-left: 16px; }

.input-search:focus,
.input-text:focus {
  border: 2px solid #2d7d90; }

.input-cta-email {
  height: 50px;
  align-self: flex-start;
  padding: 0 8px; }

.input-number {
  border: 2px solid #E9EBED;
  padding: 8px 4px; }

.input-text {
  color: #272727;
  border-radius: 4px;
  font-weight: 500;
  padding: 8px; }

.textarea {
  color: #272727;
  border: 2px solid #E9EBED;
  border-radius: 4px;
  font-weight: 500;
  padding: 8px;
  line-height: 18px; }

.input-select {
  padding: 8px;
  color: #272727; }

.hidden {
  display: none !important; }

/*    BUTTONS     */
.btn {
  width: auto;
  height: auto;
  outline: none;
  cursor: pointer;
  background: none;
  color: #FFFFFF;
  font-size: 0.93em;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  padding: 11px 24px; }

.btn:active {
  transform: scale(0.96); }

.btn-search {
  min-width: 20%;
  color: #9c9c9c;
  border: 2px solid #E9EBED;
  border-radius: 0 45px 45px 0px;
  border-left: 1px solid #E9EBED;
  transition: all 0.2s; }

.btn-search:active, .btn-search:hover {
  border: 2px solid #2d7d90;
  color: #2d7d90; }

.btn-green {
  background: #009b0e;
  border-radius: 4px; }

.btn-green:hover {
  background: #4a9c62cf; }

.btn-yellow {
  background: #ebb602;
  border-radius: 4px; }

.btn-aqua {
  border-radius: 4px;
  background: #10a7b5; }

.btn-aqua:hover {
  background: #2d7d90; }

.btn-brown {
  background: #B58316; }

.btn-brown:hover {
  background: #906811; }

.btn-red {
  border-radius: 4px;
  background-color: #E74C3C; }

.btn-red:hover {
  background-color: #C0392B; }

.btn-blue {
  background: #00a6b7; }

.btn-blue:hover {
  background: #2d7d90; }

.btn-orange {
  background: #10a7b5;
  border-radius: 4px; }

.btn-orange:hover {
  background: #2d7d90; }

.btn-gray {
  background: #9c9c9c;
  border-radius: 4px; }

.btn-darkRed {
  background: #C0392B;
  border-radius: 4px; }

.btn-darkYellow {
  background: #efba00;
  border-radius: 4px; }

.btn-pink {
  background: #ec4193;
  border-radius: 4px; }

.link {
  cursor: pointer; }

.link:hover {
  mask-image: linear-gradient(-75deg, rgba(0, 0, 0, 0.9) 30%, #000 50%, rgba(0, 0, 0, 0.6) 70%);
  mask-size: 200%;
  animation: shine 1.5s infinite; }

@keyframes shine {
  from {
    -webkit-mask-position: 150%; }
  to {
    -webkit-mask-position: -50%; } }

.table-responsive {
  overflow-x: auto; }

/*    DECORATORS & COLORS    */
.bordered {
  border: 2px solid #E9EBED; }

.pacifico {
  font-family: "Pacifico", cursive; }

.color-white {
  color: #FFFFFF; }

.color-lightGray {
  color: #E9EBED; }

.color-darkGray {
  color: #9C9C9C; }

.category-selected {
  color: #B58316 !important;
  font-weight: 700; }

.color-black {
  color: #272727; }

.color-aqua {
  color: #2d7d90; }

.color-green {
  color: #009b0e; }

.color-orange {
  color: orange; }

.color-gray {
  color: gray; }

.color-blue {
  color: #00a6b7; }

.weight-medium {
  font-weight: 500; }

.weight-bold {
  font-weight: 700; }

.uppercase {
  text-transform: uppercase; }

.text-mini {
  font-size: 0.8em; }

.pointer {
  cursor: pointer; }

.underline {
  text-decoration-line: line-through; }

/*    LARGE DEVICES    */
@media screen and (min-width: 1440px) {
  .container {
    max-width: 1440px; } }

@media screen and (max-width: 1200px) {
  body {
    background: lightcoral; } }

/*    NOTEBOOKS, IPADS DEVICES    */
@media screen and (max-width: 992px) {
  body {
    background: lightblue; }
  .break-ipad {
    flex-direction: column; } }

/*    TABLETS DEVICES    */
@media screen and (max-width: 768px) {
  body {
    background: white; }
  .row-responsive {
    flex-direction: column; }
  .white-space-64 {
    height: 32px; }
  .gutters > div {
    margin: 0; } }

/*    MOBILE DEVICES    */
@media screen and (max-width: 576px) {
  body {
    background: dimgray; }
  .input-search {
    min-height: 38px;
    margin-left: -8px;
    padding-left: 8px; } }

header {
  padding: 8px 0;
  height: 80px;
  border: 1px solid #E9EBED; }

header .logo img {
  height: 60px; }

header .cover-img {
  object-fit: contain; }

header .user p, header .signout p {
  font-size: 1.2em; }

header .signout {
  margin-left: 16px;
  cursor: pointer; }

header .signout p {
  color: #00a6b7; }

header .signout p:hover {
  color: #00a6b7; }

.workspace .aside {
  max-width: 17%;
  min-height: 100vh; }

.aside .menu-element {
  color: #9C9C9C;
  cursor: pointer;
  border-bottom: 1px rgba(221, 221, 221, 0.5) solid;
  background: white; }

.aside .menu-element:hover {
  background-color: #E9EBED;
  border-bottom: 1px solid #00a6b7; }
  .aside .menu-element:hover .icon i {
    color: #00a6b7; }

.aside .menu-element .icon {
  padding: 16px;
  width: 14%; }
  .aside .menu-element .icon i {
    color: #ec4193;
    font-size: 1.2em; }

.aside .menu-element .menu-element-name {
  font-weight: 500; }

.aside .menu-element .menu-element-name a {
  width: 80%;
  padding-left: 8px;
  padding: 16px 0;
  color: black; }

.table-responsive th {
  background-color: #E9EBED;
  padding: 10px 0; }

.table-responsive th,
.table-responsive td {
  text-align: center;
  color: #272727; }

.table-row td {
  border: 1px solid #E9EBED;
  padding: 8px 0; }

.table-image {
  display: flex;
  justify-content: center;
  align-items: center; }

.table-image img {
  max-height: 100px; }

.table-total-summary {
  justify-content: flex-end; }

.table-total-summary table {
  max-width: 40%; }

.table-total-summary td {
  padding: 16px 0; }

.table-total {
  font-weight: 700; }

.total-check {
  color: #2d7d90 !important; }

.admin .products-table {
  background-color: #FFFFFF; }

.admin .products-table .cart-container th {
  background-color: #00a6b7;
  color: white;
  border: 1px solid #E9EBED; }

.admin .products-table .cart-container .table-row {
  height: 60px; }

.admin .products-table .cart-container .btn {
  padding: 9px 24px; }

.admin .chats-table {
  background-color: #FFFFFF; }

.admin .chats-table .cart-container th {
  background-color: #FFFFFF; }

.admin .chats-table .table-row td {
  border: 1px solid #E9EBED;
  padding: 8px 0;
  height: 35px; }

.admin .chats-table .btn {
  padding: 10px 20px; }

.admin .reports-table .btn {
  padding: 4px 24px; }

.admin .products-table .cart-container .table-image img {
  max-height: 45px; }

.admin .users-table,
.admin .clients-table,
.admin .reports-table,
.admin .agencias-table {
  border: 1px solid #E9EBED; }

.admin .users-table th,
.admin .clients-table th,
.admin .reports-table th,
.admin .agencias-table th {
  background-color: #00a6b7;
  border: 1px solid #E9EBED;
  color: white; }

.admin .clients-table .table-row td,
.admin .reports-table .table-row td,
.admin .agencias-table .table-row td {
  padding: 8px;
  font-size: 0.9em;
  background: white; }

.admin .clients-table .table-row .edit, .admin .users-table .table-row .edit, .admin .agencias-table .table-row .edit {
  width: 12%; }

.admin .clients-table .table-row .delete, .admin .agencias-table .table-row .delete {
  width: 12%; }

.admin .clients-table .table-row .edit i,
.admin .products-table .table-row .edit i,
.admin .agencias-table .table-row .edit i {
  color: #00a6b7;
  font-size: 1.5em; }

.admin .clients-table .table-row .delete i,
.admin .agencias-table .table-row .delete i {
  color: #C0392B;
  font-size: 1.5em; }

.admin .clients-table .table-row .costs i {
  color: #ebb602;
  font-size: 1.5em; }

.admin .users-table .table-row {
  cursor: inherit; }

.admin .users-table .table-row {
  background: white; }
  .admin .users-table .table-row .edit .fa-key {
    color: #ebb602;
    font-size: 1.2em;
    padding-right: 15px; }
  .admin .users-table .table-row .edit .fa-pencil-alt {
    color: #00a6b7;
    font-size: 1.2em;
    padding-right: 15px; }
  .admin .users-table .table-row .edit .fa-trash {
    color: #C0392B;
    font-size: 1.2em; }
  .admin .users-table .table-row .edit .fa-save {
    color: #4A9C62;
    font-size: 1.4em;
    padding-right: 15px; }
  .admin .users-table .table-row .edit .fa-times {
    color: #C0392B;
    font-size: 1.4em; }

.admin .options-table .table-row,
.admin .prices-table .table-row {
  background: white; }
  .admin .options-table .table-row .edit .fa-key,
  .admin .prices-table .table-row .edit .fa-key {
    color: #ebb602;
    font-size: 1.2em;
    padding-right: 15px; }
  .admin .options-table .table-row .edit .fa-pencil-alt,
  .admin .prices-table .table-row .edit .fa-pencil-alt {
    color: #00a6b7;
    font-size: 1.2em; }
  .admin .options-table .table-row .edit .fa-trash,
  .admin .prices-table .table-row .edit .fa-trash {
    color: #C0392B;
    font-size: 1.2em; }
  .admin .options-table .table-row .edit .fa-save,
  .admin .prices-table .table-row .edit .fa-save {
    color: #4A9C62;
    font-size: 1.4em; }
  .admin .options-table .table-row .edit .fa-times,
  .admin .prices-table .table-row .edit .fa-times {
    color: #C0392B;
    font-size: 1.4em; }

.admin .clients-table .edit-costos .btn {
  padding: 10px 24px; }

.admin .clients-table .table-row-provider td {
  font-size: 0.9em;
  padding: 10px;
  border: 1px solid #E9EBED;
  padding: 8px 0;
  width: 10%; }

.admin .clients-table .table-row-provider {
  background: white; }

.admin .clients-table .table-row-provider .delete i {
  color: #C0392B;
  font-size: 1.5em; }

.admin .prices-table th {
  background-color: #00a6b7;
  border: 1px solid #E9EBED;
  color: white; }

.admin .prices-table .table-row td {
  padding: 8px;
  font-size: 0.9em;
  background: white; }

.options .ot {
  width: 95%; }

.admin .options-table th {
  background-color: #00a6b7;
  border: 1px solid #E9EBED;
  color: white; }

.admin .options-table .table-row td {
  padding: 8px;
  font-size: 0.9em;
  background: white; }

.admin .options-table .table-responsive th,
.admin .prices-table .table-responsive th {
  padding: 6px 0; }

.admin .options-table .table-row .edit i {
  color: #00a6b7;
  font-size: 1.3em; }

@media screen and (max-width: 992px) {
  .table-responsive th,
  .table-responsive td {
    padding: 16px 24px; }
  .th-name {
    min-width: 300px; }
  .table-total-summary table {
    max-width: 50%; } }

@media screen and (max-width: 768px) {
  .table-total-summary table {
    max-width: 100%; } }

@media screen and (max-width: 576px) {
  .th-name {
    min-width: 320px; } }

.modal {
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: fixed;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4); }

.modal-content {
  width: 80%;
  margin: auto;
  position: relative;
  background-color: #FFFFFF;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s; }

@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0; }
  to {
    top: 0;
    opacity: 1; } }

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0; }
  to {
    top: 0;
    opacity: 1; } }

.close-modal {
  color: #E74C3C;
  font-size: 1.5em;
  margin-right: 16px; }

.modal-header {
  padding: 16px 0;
  background-color: #2d7d90; }

.modal-body-container {
  align-items: center; }

.modal-body .user-data {
  width: 95%;
  justify-content: center; }

.btn-container-login .btn {
  flex-grow: 1; }

#modal-login .modal-content {
  width: 40%; }

#modal-order .cart-container .container {
  width: 95%; }

#modal-order .cart-container .table-image img {
  max-height: 40px; }

#modal-order .modal-content
.btn-container-accept {
  min-width: 60%; }

#modal-order .modal-content
.btn-container-accept button {
  flex-grow: 1; }

#modal-order .modal-content
.btn-container-cancel button {
  flex-grow: 1; }

#modal-order .table-total-summary table {
  max-width: 100%; }

#modal-order .table-client .table-clientInfo,
#modal-order .table-client .table-clientAddress {
  padding: 16px 8px; }

#modal-add-user .modal-content {
  width: 35%; }
  #modal-add-user .modal-content .modal-header {
    background: darkslategray; }
  #modal-add-user .modal-content .btn-container-login .btn-aqua {
    border-radius: 0; }
  #modal-add-user .modal-content .modal-body label {
    color: #9C9C9C; }
  #modal-add-user .modal-content #close-modal-delete {
    width: 50%;
    padding: 16px;
    border-radius: 0; }

#modal-add-user .users-data {
  width: 90%; }

#modal_delete_costo .modal-content {
  width: 30%; }

.modal-password .modal-header {
  background: darkslategray; }

.modal-password .modal-content {
  width: 40%; }
  .modal-password .modal-content .users-data {
    width: 90%; }
  .modal-password .modal-content .fa-times {
    color: azure;
    cursor: pointer; }
  .modal-password .modal-content .btn-aqua {
    border-radius: 0; }
  .modal-password .modal-content button {
    width: 50%;
    padding: 16px;
    border-radius: 0; }

#detail-question-modal .modal-content {
  width: 30%; }

#detail-message-modal .modal-content {
  width: 25%; }

#modal_delete_category .modal-content,
#modal_delete_price .modal-content,
#modal_delete_agencia .modal-content {
  width: 25%; }

#modal-add-tc .modal-content {
  width: 25%; }
  #modal-add-tc .modal-content .modal-header {
    background-color: darkslategray; }
  #modal-add-tc .modal-content #close-modal-delete {
    width: 40%;
    padding: 16px;
    border-radius: 0; }

#modal-end-conversation .modal-content {
  background-color: #272727;
  width: 30%;
  margin: auto;
  position: relative;
  align-self: flex-start; }
  #modal-end-conversation .modal-content #btn-deleteChat {
    width: 50%;
    padding: 16px;
    border-radius: 0; }
  #modal-end-conversation .modal-content #close-modal-delete {
    width: 50%;
    padding: 16px;
    border-radius: 0; }

#modal-reservation-detail .modal-content {
  background-color: #FFFFFF;
  width: 50%;
  margin: auto;
  position: relative;
  align-self: flex-start; }
  #modal-reservation-detail .modal-content .modal-head {
    background: #272727; }
    #modal-reservation-detail .modal-content .modal-head .close-modal-reservation a {
      padding-right: 12px;
      color: white;
      padding-top: 8px;
      font-weight: 600;
      cursor: pointer; }
  #modal-reservation-detail .modal-content .reservation-detail-inputs {
    padding: 0 10px; }
    #modal-reservation-detail .modal-content .reservation-detail-inputs .change-status .input-status {
      border: 2px solid #7da2ab;
      padding: 8px;
      color: #272727;
      width: 100%; }
    #modal-reservation-detail .modal-content .reservation-detail-inputs .change-status .save, #modal-reservation-detail .modal-content .reservation-detail-inputs .change-status .cancel {
      width: 80%; }
    #modal-reservation-detail .modal-content .reservation-detail-inputs .change-status .btn-save {
      width: 90%;
      color: white;
      border-radius: 4px;
      cursor: pointer;
      font-size: 1em;
      font-weight: 600; }
    #modal-reservation-detail .modal-content .reservation-detail-inputs .first {
      border-bottom: 1px solid gray; }
      #modal-reservation-detail .modal-content .reservation-detail-inputs .first .up .up-data .left {
        width: 50%; }
      #modal-reservation-detail .modal-content .reservation-detail-inputs .first .up h4 {
        color: #2d7d90; }
      #modal-reservation-detail .modal-content .reservation-detail-inputs .first .down {
        padding-left: 40px; }
        #modal-reservation-detail .modal-content .reservation-detail-inputs .first .down .down-data .left {
          width: 40%; }
      #modal-reservation-detail .modal-content .reservation-detail-inputs .first .down h4 {
        color: #2d7d90; }
    #modal-reservation-detail .modal-content .reservation-detail-inputs .middle {
      border-bottom: 1px solid gray; }
      #modal-reservation-detail .modal-content .reservation-detail-inputs .middle .left .middle-data .left {
        width: 70%; }
      #modal-reservation-detail .modal-content .reservation-detail-inputs .middle .left .middle-data .right {
        padding-left: 0; }
      #modal-reservation-detail .modal-content .reservation-detail-inputs .middle .right {
        padding-left: 40px; }
        #modal-reservation-detail .modal-content .reservation-detail-inputs .middle .right .middle-data .left {
          width: 65%; }
        #modal-reservation-detail .modal-content .reservation-detail-inputs .middle .right .middle-data .right {
          padding-left: 0; }
        #modal-reservation-detail .modal-content .reservation-detail-inputs .middle .right .whats-provider i {
          color: green;
          font-size: 2.2em; }
        #modal-reservation-detail .modal-content .reservation-detail-inputs .middle .right .provider-status label {
          padding-right: 10px; }
    #modal-reservation-detail .modal-content .reservation-detail-inputs .middle h4 {
      color: #2d7d90; }
  #modal-reservation-detail .modal-content .reservation-detail-data input {
    padding: 4px 12px;
    font-size: 14px;
    border: 1px solid #7da2ab;
    border-radius: .25rem; }
  #modal-reservation-detail .modal-content #btn-deleteChat {
    width: 50%;
    padding: 16px;
    border-radius: 0; }
  #modal-reservation-detail .modal-content #close-modal-delete {
    width: 50%;
    padding: 16px;
    border-radius: 0; }

#modal-add-hotel .modal-content, #modal-add-agencia .modal-content {
  background-color: #FFFFFF;
  width: 30%;
  margin: auto;
  position: relative;
  align-self: flex-start; }
  #modal-add-hotel .modal-content .modal-head, #modal-add-agencia .modal-content .modal-head {
    background: darkslategray; }
  #modal-add-hotel .modal-content .hotel-inputs, #modal-add-agencia .modal-content .hotel-inputs {
    padding: 0 10px; }
    #modal-add-hotel .modal-content .hotel-inputs .hotel-status label, #modal-add-agencia .modal-content .hotel-inputs .hotel-status label {
      padding-right: 10px; }
  #modal-add-hotel .modal-content .hotel-data input, #modal-add-agencia .modal-content .hotel-data input {
    padding: 4px 12px;
    font-size: 14px;
    border: 1px solid #7da2ab;
    border-radius: .25rem; }
  #modal-add-hotel .modal-content #btn-deleteChat, #modal-add-agencia .modal-content #btn-deleteChat {
    width: 50%;
    padding: 16px;
    border-radius: 0; }
  #modal-add-hotel .modal-content #close-modal-delete, #modal-add-agencia .modal-content #close-modal-delete {
    width: 50%;
    padding: 16px;
    border-radius: 0; }

#modal-add-proveedor .modal-content {
  background-color: #FFFFFF;
  width: 30%;
  margin: auto;
  position: relative;
  align-self: flex-start; }
  #modal-add-proveedor .modal-content .modal-head {
    background: darkslategray; }
  #modal-add-proveedor .modal-content .provider-data input {
    padding: 4px 12px;
    font-size: 14px;
    border: 1px solid #7da2ab;
    border-radius: .25rem; }
  #modal-add-proveedor .modal-content .provider-inputs {
    padding: 0 10px; }
    #modal-add-proveedor .modal-content .provider-inputs .provider-status label {
      padding-right: 10px; }
  #modal-add-proveedor .modal-content #btn-deleteChat {
    width: 50%;
    padding: 16px;
    border-radius: 0; }
  #modal-add-proveedor .modal-content #close-modal-delete {
    width: 50%;
    padding: 16px;
    border-radius: 0; }

#modal-horarios .modal-content {
  width: 40%; }
  #modal-horarios .modal-content #btn-yes {
    width: 50%;
    padding: 16px;
    border-radius: 0; }
  #modal-horarios .modal-content #btn-no {
    width: 50%;
    padding: 16px;
    border-radius: 0; }
  #modal-horarios .modal-content .modal-head .horarios {
    background: darkslategray; }

#modal_provider_confirmation .modal-content-confirmation {
  width: 45%;
  margin: auto;
  position: relative;
  background-color: #FFFFFF;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s; }

.close-banner {
  position: absolute;
  right: 0;
  top: 5px;
  width: 30px;
  height: 30px;
  display: block;
  overflow: hidden; }

.close-banner i {
  color: white;
  font-size: 1.8em;
  cursor: pointer; }

.info-product {
  position: relative;
  margin-right: 36px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 4px;
  box-shadow: 0px 0px 20px 6px #9E9E9E; }

.banner-b {
  /*position: absolute;
  bottom: 45px;*/
  height: 100vh; }

.text-banner {
  /*margin-bottom: 210px;*/
  height: 45vh; }

.text-banner h1 {
  font-size: 2em;
  font-family: 'Della Respira'; }

.btn-banner {
  box-shadow: 0 0 16px 4px;
  width: 25%;
  border-radius: 4px;
  padding: 10px 24px;
  font-size: 1.2em;
  font-family: 'Raleway'; }

.banner-content {
  width: 55%;
  background: transparent;
  box-shadow: none; }

.modal-body-banner {
  position: relative;
  height: 450px; }

.color-text-banner .slider-picker {
  align-items: center;
  width: 50%;
  flex-direction: column; }

.color-text-banner .swatches-picker div {
  display: block; }

@media screen and (max-width: 1200px) {
  #modal-add-user .modal-content {
    width: 60%; }
  #modal-add-user .users-data {
    width: 96%; } }

@media screen and (max-width: 992px) {
  #modal-login .modal-content {
    width: 50%; } }

@media screen and (max-width: 768px) {
  #modal-login .modal-content {
    width: 65%; } }

@media screen and (max-width: 768px) {
  #modal-login .modal-content {
    width: 80%; } }

#modal-precios .modal-content {
  width: 70%; }
  #modal-precios .modal-content .modal-head {
    background: darkslategray;
    position: relative; }
    #modal-precios .modal-content .modal-head .modal-close {
      position: absolute;
      top: 20px;
      right: 20px;
      justify-content: flex-end; }
  #modal-precios .modal-content .modal-body {
    display: flex;
    justify-content: center; }
  #modal-precios .modal-content table {
    max-width: 100%; }
    #modal-precios .modal-content table tr td {
      border: 1px solid #E9EBED;
      padding: 5px 0px; }
  #modal-precios .modal-content .input-text {
    max-width: 80%; }

/*.pagination .page {       
    //border: 4px solid $white;
    transition: all 0.1s;
}*/
/*.pagination .page:hover {
    background-color: $aqua;  
    border: 4px solid $aqua;  
    color: $white;
}*/
.pagination .page-link {
  padding: 8px 13px;
  color: black;
  float: left;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #9c9c9c99;
  margin: 0 4px;
  border-radius: 20px; }

.page a:hover:not(.active) {
  color: #4A9C62;
  border-color: #4A9C62;
  cursor: pointer; }

.pagination .current-page a {
  border-color: #00a6b7;
  color: #00a6b7; }

/*.pagination .current-page {
    border: 4px solid $aqua;
}

.pagination .current-page a {
    background: $aqua;
    color: $white;
}*/
/* ==========================================================================
    Products
========================================================================== */
.products-container {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  grid-gap: 16px; }

.product {
  min-height: 340px;
  margin-bottom: 16px; }

.product:hover {
  border: 2px solid #2d7d90; }

.product .image {
  justify-content: center; }

.product .image img {
  height: 220px; }

.product .description {
  background-color: #E9EBED;
  flex-grow: 1;
  padding: 16px 0 0 0; }

.product .description .name {
  font-size: 1em;
  font-weight: 900;
  letter-spacing: 0.5px;
  min-height: 40px; }

.product .description .price {
  font-weight: 900;
  font-size: 1.6em; }

.product .btn {
  flex-grow: 1; }

.products-main .products-container {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  grid-gap: 16px; }

@media screen and (max-width: 1200px) {
  .products-container {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 16px 8px; } }

@media screen and (max-width: 992px) {
  .products-container {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)); } }

@media screen and (max-width: 768px) {
  .products-container {
    display: flex !important;
    justify-content: space-between;
    flex-wrap: wrap; }
  .product {
    min-height: 340px;
    margin-bottom: 16px; } }

.login {
  min-height: 100vh;
  background-color: #F4F5F6; }

.login-modal {
  width: 40%;
  background-color: #FFFFFF;
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.05); }
  .login-modal .useri i {
    font-size: 4em; }
  .login-modal h2 {
    font-size: 1.4em; }
  .login-modal .btn-green {
    border-radius: 4px;
    width: 84%; }

.login .logo img {
  max-height: 65px; }

.login form input::placeholder {
  text-align: center; }

.login form input {
  width: 80%;
  text-align: center; }

.login form .btn {
  flex-grow: 1; }

.login-error {
  border: 2px solid #E74C3C; }

@media screen and (max-width: 992px) {
  .login-modal {
    width: 50%; } }

@media screen and (max-width: 768px) {
  .login-modal {
    width: 60%; } }

@media screen and (max-width: 576px) {
  .login-modal {
    width: 100%; } }

.admin-dashboard-cards .card-clients {
  color: #FFFFFF;
  border-radius: 4px;
  align-self: flex-start;
  padding: 32px 0;
  background-size: cover;
  background-position: center;
  box-shadow: 0 0 6px rgba(33, 33, 33, 0.1); }

.admin-dashboard-cards .card-products,
.admin-dashboard-cards .card-orders {
  color: #FFFFFF;
  border-radius: 4px;
  align-self: flex-start;
  padding: 40px 0;
  background-size: cover;
  box-shadow: 0 0 6px rgba(33, 33, 33, 0.1); }

.summary-orders-table {
  background-color: #FFFFFF;
  box-shadow: 0 0 6px rgba(33, 33, 33, 0.1); }

.summary-orders-table th {
  background-color: #00a6b7;
  color: white;
  border: 1px solid #ddd; }

.admin .workspace .summary-orders-table .table-row {
  cursor: pointer; }

.admin .workspace .summary-orders-table .table-row:hover {
  background-color: #E9EBED; }

/*.admin .pagination .page {
    background-color: $lightGray;
    border: 1px solid $lightGray;
}

.admin .pagination .page:hover {
    background-color: $aqua;
    border: 1px solid $white;
}*/
.admin .form-filter-products input[type="text"] {
  min-width: 60%; }

.admin .form-filter-products .search-input {
  padding-right: 8px; }
  .admin .form-filter-products .search-input .input {
    width: 100%; }

.product-b {
  background: white;
  margin: 0 40px; }

.product-images {
  padding: 15px 25px;
  width: 45%; }
  .product-images .principal-img {
    position: relative; }
    .product-images .principal-img img {
      width: 100%;
      object-fit: cover;
      object-position: center;
      margin-left: 0 !important; }
    .product-images .principal-img .rd-parallax-gallery {
      /*opacity: 0;
            transition: .3s all ease;*/
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: 300ms all ease;
      opacity: 0; }
      .product-images .principal-img .rd-parallax-gallery .top-text {
        cursor: pointer; }
      .product-images .principal-img .rd-parallax-gallery .top-text i {
        color: white;
        font-size: 2.3em;
        cursor: pointer; }
    .product-images .principal-img:hover > .rd-parallax-gallery {
      background: linear-gradient(to bottom, transparent 5%, rgba(0, 0, 0, 0.8));
      opacity: 1; }
  .product-images .gallery {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0.5rem; }
  .product-images .gallery-img img {
    height: 100px;
    object-fit: cover;
    object-position: center;
    width: 100%; }
  .product-images .gallery-img {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.2); }
    .product-images .gallery-img .rd-parallax-gallery {
      /*opacity: 0;
            transition: .3s all ease;*/
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: 300ms all ease;
      opacity: 0; }
      .product-images .gallery-img .rd-parallax-gallery .top-text {
        cursor: pointer; }
      .product-images .gallery-img .rd-parallax-gallery .top-text i {
        color: white;
        font-size: 2.3em;
        cursor: pointer; }
    .product-images .gallery-img:hover > .rd-parallax-gallery {
      background: linear-gradient(to bottom, transparent 5%, rgba(0, 0, 0, 0.8));
      opacity: 1; }

.product-info .container {
  width: 85%; }
  .product-info .container button {
    width: 40%; }

.color-text-banner .swatches-picker div {
  display: block; }

.icon-button {
  cursor: pointer; }

.space-11 {
  margin-left: 11px; }

.width-80 {
  width: 80%; }

.admin .container-add-product {
  background-color: #FFFFFF; }

.admin .container-add-product .left .product-image-four .product-img, .admin .container-add-product .left .product-image-preview .product-img {
  background: black;
  position: relative;
  border-radius: 4px; }
  .admin .container-add-product .left .product-image-four .product-img .rd-parallax-gallery, .admin .container-add-product .left .product-image-preview .product-img .rd-parallax-gallery {
    /*opacity: 0;
                transition: .3s all ease;*/
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 300ms all ease;
    opacity: 0; }
    .admin .container-add-product .left .product-image-four .product-img .rd-parallax-gallery .top-text i, .admin .container-add-product .left .product-image-preview .product-img .rd-parallax-gallery .top-text i {
      color: white;
      font-size: 2.3em;
      cursor: pointer; }
  .admin .container-add-product .left .product-image-four .product-img:hover > .rd-parallax-gallery, .admin .container-add-product .left .product-image-preview .product-img:hover > .rd-parallax-gallery {
    background: linear-gradient(to bottom, transparent 5%, rgba(0, 0, 0, 0.8));
    opacity: 1;
    cursor: pointer; }

.admin .container-add-product .left .product-image-four .product-img .rd-parallax-gallery .top-text i {
  font-size: 1.3em; }

.admin .container-add-product .left .product-image-four .product-img img {
  height: 93.10px;
  width: 100%; }

.admin .container-add-product .right .htmlForm-add-product .tabs .steps {
  padding: 4px 0; }
  .admin .container-add-product .right .htmlForm-add-product .tabs .steps .step-circle {
    background: gray;
    padding: 5px 11px;
    border-radius: 50%;
    margin-right: 8px;
    cursor: pointer; }
  .admin .container-add-product .right .htmlForm-add-product .tabs .steps .step-active {
    background: #2d7d90;
    padding: 5px 11px;
    border-radius: 50%;
    margin-right: 8px;
    cursor: pointer; }
  .admin .container-add-product .right .htmlForm-add-product .tabs .steps:hover .step-circle {
    background-color: #2d7d90;
    cursor: pointer; }
  .admin .container-add-product .right .htmlForm-add-product .tabs .steps:hover .step-text h3 {
    color: #2d7d90;
    cursor: pointer; }

.admin .container-add-product .right .step1 {
  /*.link #product_link {
            width: 90%;
        } */ }
  .admin .container-add-product .right .step1 .space input {
    width: 90%; }
  .admin .container-add-product .right .step1 .limit {
    width: 40%; }
    .admin .container-add-product .right .step1 .limit #product_limit {
      width: 90%; }
  .admin .container-add-product .right .step1 .price {
    width: 100%; }

.admin .container-add-product .right .options {
  background-color: #eee;
  width: 90%;
  border-radius: 5px; }

.admin .container-add-product .right .add-options {
  width: 90%; }
  .admin .container-add-product .right .add-options .input-text {
    width: 50%; }

.admin .container-add-product .right .file {
  width: 50%; }
  .admin .container-add-product .right .file label {
    font-weight: 400;
    color: white; }

.admin .container-add-product .right .file-slider {
  width: 60%; }
  .admin .container-add-product .right .file-slider label {
    font-weight: 400;
    color: white; }

.admin .container-add-product .right .pdf i {
  font-size: 1.1em;
  color: red; }

.admin .container-add-product .left p {
  font-weight: 600;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.9); }

.admin .container-add-product .right label {
  font-weight: 600;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.9); }

.section-selects .input-select {
  width: 90%;
  border-radius: 4px; }

.container .container-add-product {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
  border-radius: 2px; }

.admin .container-add-product .right {
  min-width: 60%; }

.admin .product-image-preview {
  width: 480px; }

.admin .product-image-four {
  width: 480px;
  display: grid !important;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.5rem; }

.admin #product-image-esp, #product-image-ing, #product-image-pt {
  cursor: pointer;
  border-radius: 4px;
  height: 204px; }

.admin #upload-product-image-input {
  display: none; }

.admin .prices {
  width: 75%; }

.admin .price-div {
  font-weight: 900;
  font-size: 1.2em; }
  .admin .price-div .input-text {
    max-width: 140px; }

.admin .container-add-product input[name="product-price"] {
  font-weight: 900;
  color: #2d7d90; }

.form-add-product {
  width: 100%; }

.images-map {
  display: flex;
  width: 100%;
  overflow: auto; }

.prices-calendar {
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #7da2ab;
  border-radius: .25rem;
  width: 20%; }

.prices-table,
.bloqueo {
  background: #eee;
  width: 90%;
  border-radius: 5px; }
  .prices-table .input-text,
  .bloqueo .input-text {
    width: 80%; }

.chat {
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1); }
  .chat .content-chat-list {
    background-color: #fff;
    height: 519px;
    width: 55%;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; }
    .chat .content-chat-list .chat-search {
      max-width: 100%;
      margin-bottom: 16px; }
    .chat .content-chat-list .chat-search i {
      color: #8f8f8f;
      position: absolute;
      font-size: 0.9rem;
      margin-left: 8px;
      padding-left: 16px; }
    .chat .content-chat-list .chat-search input {
      width: 100%;
      border-radius: 16px;
      margin: 0 12px;
      background-color: #d8d8d8;
      font-size: 1rem;
      font-family: "Roboto", sans-serif;
      font-weight: 600;
      outline: 1px solid transparent;
      padding: 8px 16px 8px 36px; }
    .chat .content-chat-list .chat-list {
      min-height: 90px;
      position: relative;
      cursor: pointer; }
      .chat .content-chat-list .chat-list .icon-delete {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 1.2rem;
        color: #0c1b42; }
      .chat .content-chat-list .chat-list .icon-nuevo {
        position: absolute;
        display: flex;
        justify-content: flex-end;
        right: 10px;
        top: 10px;
        font-size: 0.8rem;
        color: #ac0000; }
      .chat .content-chat-list .chat-list .chat-details {
        padding: 0 10px 0 20px;
        border-bottom: 1px solid #ddd;
        /*.chat-status i {
                    color: $green;
                }*/ }
        .chat .content-chat-list .chat-list .chat-details .chat-name {
          padding-right: 4px; }
    .chat .content-chat-list .list {
      overflow-y: auto; }
  .chat .chat-content {
    height: 519px;
    border-left: 1px solid #d1d1d1;
    background-color: white;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }
    .chat .chat-content .header-chat {
      background-color: #0c1b42;
      height: auto;
      border-top-right-radius: 4px; }
      .chat .chat-content .header-chat .header-profile {
        padding: 14px 0 14px 40px; }
      .chat .chat-content .header-chat .end-conversation .btn:hover {
        color: red; }
    .chat .chat-content .content-gral-chat {
      height: 385px; }
      .chat .chat-content .content-gral-chat .content-chat {
        overflow-y: auto;
        padding: 0 12px; }
        .chat .chat-content .content-gral-chat .content-chat #chat_messages {
          align-self: flex-start; }
        .chat .chat-content .content-gral-chat .content-chat .message-me p {
          max-width: 90%;
          border-radius: 8px;
          padding: 16px 8px;
          background-color: rgba(62, 166, 214, 0.4); }
        .chat .chat-content .content-gral-chat .content-chat .message-user p {
          max-width: 90%;
          border-radius: 8px;
          padding: 16px 8px;
          background-color: rgba(187, 187, 187, 0.4);
          margin: 4px 0; }
    .chat .chat-content .bottom-controls {
      /*bottom: 28px;
            z-index: 1;*/
      padding: 12px 0;
      position: relative;
      background-color: #fff;
      box-shadow: 0 -0.1px 5px 0 #d9d9d9;
      border-bottom-right-radius: 4px; }
      .chat .chat-content .bottom-controls .container-controls {
        width: 100%;
        padding-left: 16px; }
        .chat .chat-content .bottom-controls .container-controls .left {
          width: 90%; }
        .chat .chat-content .bottom-controls .container-controls .left input {
          width: 98%;
          font-size: 1rem;
          font-family: "Roboto", sans-serif;
          font-weight: 600;
          outline: 1px solid transparent;
          background-color: #efefef;
          padding: 8px 16px;
          border-radius: 4px; }
        .chat .chat-content .bottom-controls .container-controls .right {
          padding-right: 8px; }
          .chat .chat-content .bottom-controls .container-controls .right .icon-sent-image {
            font-size: 1.4rem; }
          .chat .chat-content .bottom-controls .container-controls .right .btn-send {
            background-color: initial;
            padding: 0 8px; }
            .chat .chat-content .bottom-controls .container-controls .right .btn-send .icon-sent {
              font-size: 1.8rem;
              margin-left: 8px;
              color: #4A9C62; }
    .chat .chat-content .no-messages h3 {
      color: black; }

.admin .workspace .control-content .get-reservations {
  width: 60%; }
  .admin .workspace .control-content .get-reservations .select-report {
    padding-right: 10px; }
    .admin .workspace .control-content .get-reservations .select-report .input-select {
      padding: 9px; }
  .admin .workspace .control-content .get-reservations .data-to {
    padding-left: 10px; }
  .admin .workspace .control-content .get-reservations .get-results {
    padding-left: 20px;
    width: 60%; }
    .admin .workspace .control-content .get-reservations .get-results .btn {
      padding: 11px 24px; }

.admin .workspace .control-content .get-reports {
  width: 80%; }
  .admin .workspace .control-content .get-reports .select-report {
    padding-right: 10px; }
    .admin .workspace .control-content .get-reports .select-report .input-select {
      padding: 9px; }
  .admin .workspace .control-content .get-reports .data-to {
    width: 60%;
    padding-left: 10px; }
  .admin .workspace .control-content .get-reports .data-from {
    width: 60%; }
  .admin .workspace .control-content .get-reports .get-results {
    padding-left: 20px; }
    .admin .workspace .control-content .get-reports .get-results .btn {
      padding: 11px 24px; }

.admin .workspace .control-content .filters {
  width: 80%; }
  .admin .workspace .control-content .filters .input-select {
    padding: 7px; }

.admin .workspace .control-content .button-filter {
  padding-left: 20px; }

.admin .workspace .control-content .button-filter .btn {
  padding: 9px 24px; }

.reservations-table {
  background-color: #FFFFFF;
  box-shadow: 0 0 6px rgba(33, 33, 33, 0.1); }

.reservations-table th {
  background-color: #00a6b7;
  color: white;
  border: 1px solid #ddd; }

.admin .workspace .reservations-table .table-row {
  cursor: pointer; }

.admin .workspace .reservations-table .table-row:hover {
  background-color: #E9EBED; }

.payment {
  background-color: #E9EBED; }
  .payment h3, .payment h4 {
    color: black; }
  .payment .payment-sections {
    padding-top: 25px; }
    .payment .payment-sections .payment-left-right {
      box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1); }
      .payment .payment-sections .payment-left-right .payment-left {
        padding: 50px 25px 40px 25px;
        width: 40%;
        background: white;
        border-right: 2px solid #ddd;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px; }
      .payment .payment-sections .payment-left-right .payment-right {
        width: 60%;
        padding: 50px 25px 40px 25px;
        background: white;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px; }
        .payment .payment-sections .payment-left-right .payment-right .pay .paydata .cardstype .creditcard {
          width: 55%; }
        .payment .payment-sections .payment-left-right .payment-right .pay .paydata .cardstype .debitcard {
          border-left: 1px solid;
          padding-left: 10px; }
        .payment .payment-sections .payment-left-right .payment-right .pay .paydata .cardstype .creditcard h4, .payment .payment-sections .payment-left-right .payment-right .pay .paydata .cardstype .debitcard h4 {
          font-size: 0.8em; }
        .payment .payment-sections .payment-left-right .payment-right .pay .paydata .input-card {
          position: relative; }
        .payment .payment-sections .payment-left-right .payment-right .pay .paydata .input-card .fab {
          position: absolute;
          top: 5px;
          right: 3%;
          font-size: 18px; }
        .payment .payment-sections .payment-left-right .payment-right .pay .paydata .input-pay {
          width: 100%;
          padding: 6px 12px;
          font-size: 13px;
          border: 1px solid #7da2ab;
          border-radius: .25rem; }
        .payment .payment-sections .payment-left-right .payment-right .pay .paydata .input-left {
          margin-right: 10px;
          /*width: 100%;
                        padding: 4px 12px;
                        font-size: 14px;
                        border: 1px solid #7da2ab;
                        border-radius: .25rem;*/ }
        .payment .payment-sections .payment-left-right .payment-right .pay .paydata .input-right {
          margin-left: 10px;
          /*width: 100%;
                        padding: 4px 12px;
                        font-size: 14px;
                        border: 1px solid #7da2ab;
                        border-radius: .25rem;*/ }
        .payment .payment-sections .payment-left-right .payment-right .pay .paydata .info-openpay .openpay {
          border-right: 1px solid; }
        .payment .payment-sections .payment-left-right .payment-right .pay .paydata .info-openpay .openpay h4 {
          font-size: 0.9em; }
        .payment .payment-sections .payment-left-right .payment-right .pay .paydata .info-openpay .security {
          width: 48%; }
    .payment .payment-sections .btn-container {
      width: auto;
      padding-right: 190px; }
      .payment .payment-sections .btn-container .btn-pay {
        width: 25%;
        cursor: pointer;
        background-color: #ebb602;
        border-radius: 5px;
        font-size: 1.1em;
        height: 45px;
        color: white;
        transition: background-color  .5s; }
        .payment .payment-sections .btn-container .btn-pay:hover {
          background-color: orange; }

.admin .workspace .control-content .add-tour {
  /*.adult-price input, .child-price input {
        height: 35px;
    }*/ }
  .admin .workspace .control-content .add-tour .select-tour .input-select {
    width: 100%; }
  .admin .workspace .control-content .add-tour .adult-price {
    padding: 0 10px; }
  .admin .workspace .control-content .add-tour .button-filter {
    padding-left: 15px;
    /*.btn-green {
            border-radius: 2px;
        }*/ }

.reports .get-reports {
  width: 100% !important; }
  .reports .get-reports .get-result {
    padding-left: 20px; }

.reports .get-results {
  width: 30%; }

.reports .reports-table {
  max-height: 640px; }

.admin .workspace .control-content {
  background-color: #E9EBED;
  max-width: 83%; }

.admin .container {
  width: 95%; }
