.admin .workspace .control-content .get-reservations {
    width: 60%;

    .select-report {
        padding-right: 10px;

        .input-select {
            padding: 9px;
        }
    }

    .data-to {
        padding-left: 10px;
    }

    .get-results {
        padding-left: 20px;
        width: 60%;

        .btn {
            padding: 11px 24px;
        }
    }
}

.admin .workspace .control-content .get-reports {
    width: 80%;

    .select-report {
        padding-right: 10px;

        .input-select {
            padding: 9px;
        }
    }

    .data-to {
        width: 60%;
        padding-left: 10px;
    }

    .data-from {
        width: 60%;
    }

    .get-results {
        padding-left: 20px;

        .btn {
            padding: 11px 24px;
        }
    }
}

.admin .workspace .control-content .filters {
    width: 80%;

    .input-select {
        padding: 7px;
    }
}

.admin .workspace .control-content .button-filter {
    padding-left: 20px;
    //width: 45%;
}

.admin .workspace .control-content .button-filter .btn {
    padding: 9px 24px;
}

.reservations-table {
    background-color: $white;
    box-shadow: 0 0 6px rgba(33,33,33,.1);
}

.reservations-table th {
    background-color: $blue;
    color: white;
    border: 1px solid #ddd;
}

.admin .workspace .reservations-table .table-row {
    cursor: pointer;
}

.admin .workspace .reservations-table .table-row:hover {
    background-color: $lightGray;    
}
