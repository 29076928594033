
.login {
    min-height: 100vh;
    background-color: $softGray;
}

.login-modal {
    width: 40%;
    background-color: $white;
    box-shadow: 1px 0 20px rgba(0,0,0,.05); 
    //border-radius: 4px;

    .useri i {
        font-size: 4em;
    }

    h2 {
        font-size: 1.4em;
    }

    .btn-green {
        border-radius: 4px;
        width: 84%;
    }
}

.login .logo img {
    max-height: 65px;
}

.login form input::placeholder {
    text-align: center;
}

.login form input {
    width: 80%;
    text-align: center;
}

.login form .btn {
    flex-grow: 1;
}

.login-error {
    border: 2px solid $red;
}

@media screen and (max-width: 992px) {

    .login-modal {
        width: 50%;
    }  

}

@media screen and (max-width: 768px) {

    .login-modal {
        width: 60%;
    }    

}

@media screen and (max-width: 576px) {

    .login-modal {
        width: 100%;
    }  

}