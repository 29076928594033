
.workspace .aside {
    max-width: 17%;
    min-height: 100vh;
}

.aside .menu-element {
    color: $darkGray;   
    cursor: pointer;
    border-bottom: 1px rgba(#ddd,0.5) solid;
    background: white;
}

.aside .menu-element:hover {
    background-color: #E9EBED;
    border-bottom: 1px solid $blue;

    .menu-element-name a {
        //color: $white;
    }

    .icon i {
        color: $blue;
    }
}

.aside .menu-element .icon {
    padding: $paddingMedium;
    width: 14%;

    i {
        color: /*#fe0000*/$pink;
        font-size: 1.2em;
    }
}

.aside .menu-element .menu-element-name {    
    font-weight: $weightMedium;    
}

.aside .menu-element .menu-element-name a {
    width: 80%;
    padding-left: $paddingNormal;
    padding: $paddingMedium 0;
    color: black;
}