.payment {
    background-color: #E9EBED;

    h3, h4 {
        color: black;
    }

    .payment-sections {
        padding-top: 25px;

        .payment-left-right {
        box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);

            .payment-left {
                padding: 50px 25px 40px 25px;
                width: 40%;
                background: white;
                border-right: 2px solid #ddd;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }
            
            .payment-right {
                width: 60%;
                padding: 50px 25px 40px 25px;
                background: white;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                //background-color: beige;

                .pay .paydata {
                    .cardstype {
                        .creditcard {
                            width: 55%;
                        }

                        .debitcard {
                            border-left: 1px solid;
                            padding-left: 10px;
                        }
                        
                        .creditcard h4, .debitcard h4 {
                            font-size: 0.8em;
                        }
                    }

                    .input-card {
                        position: relative;
                    }

                    .input-card .fab {
                        position: absolute;
                        top: 5px;
                        right: 3%;
                        font-size: 18px;
                    }

                    .input-pay {
                        width: 100%;
                        padding: 6px 12px;
                        font-size: 13px;
                        border: 1px solid #7da2ab;
                        border-radius: .25rem;
                    }

                    .input-left {
                        margin-right: 10px; 
                        /*width: 100%;
                        padding: 4px 12px;
                        font-size: 14px;
                        border: 1px solid #7da2ab;
                        border-radius: .25rem;*/
                    }

                    .input-right {
                        margin-left: 10px; 
                        /*width: 100%;
                        padding: 4px 12px;
                        font-size: 14px;
                        border: 1px solid #7da2ab;
                        border-radius: .25rem;*/
                    }

                    .info-openpay {
                        .openpay {
                            border-right: 1px solid;
                        }

                        .openpay h4 {
                            font-size: 0.9em;
                        }

                        .security {
                            width: 48%;
                        }
                    }
                }
            }
        }

        .btn-container {
            width: auto;
            padding-right: 190px;

            .btn-pay {
                width: 25%;
                cursor: pointer;
                background-color: $yellow;
                border-radius: 5px;
                font-size: 1.1em;
                height: 45px;
                color: white;
                transition: background-color  .5s;
                &:hover {
                    background-color: orange;
                }
            }
        }
    }
}