.reports {
    .get-reports {
        width: 100% !important;

        .get-result {
            padding-left: 20px;
        }
    }

    .get-results {
        width: 30%;
    }

    .reports-table {
        max-height: 640px;
    }
}