
.admin-dashboard-cards .card-clients {    
    color: $white;
    border-radius: 4px;
    align-self: flex-start;
    padding: $paddingLarge 0;
    background-size: cover;
    background-position: center;
    box-shadow: 0 0 6px rgba(33,33,33,.1);
}

.admin-dashboard-cards .card-products,
.admin-dashboard-cards .card-orders {
    color: $white;
    border-radius: 4px;
    align-self: flex-start;
    padding: 40px 0;
    background-size: cover;
    box-shadow: 0 0 6px rgba(33,33,33,.1);
}

.summary-orders-table {
    background-color: $white;
    box-shadow: 0 0 6px rgba(33,33,33,.1);
}

.summary-orders-table th {
    background-color: /*#ec4193*/$blue;
    color: white;
    border: 1px solid #ddd;
}

//colors --> #00a6b7(azul), #ebb602(amarillo), #009b0e(verde)
