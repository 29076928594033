.admin .workspace .control-content .add-tour {
    .select-tour .input-select {
        width: 100%;
    }

    .adult-price {
        padding: 0 10px;
    }

    /*.adult-price input, .child-price input {
        height: 35px;
    }*/

    .button-filter {
        padding-left: 15px;

        /*.btn-green {
            border-radius: 2px;
        }*/
    } 
} 