
.table-responsive th {
    background-color: $lightGray;
    padding: 10px 0;
}

.table-responsive th,
.table-responsive td {
    text-align: center;
    color: $black;
}

.table-row td {
    border: 1px solid $lightGray;
    padding: $paddingNormal 0;
}

.table-image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-image img {
    max-height: 100px; 
}

.table-total-summary {
    justify-content: flex-end;     
}

.table-total-summary table {
    max-width: 40%;    
}

.table-total-summary td {
    padding: $paddingMedium 0;
}

.table-total {
    font-weight: $weightBold;   
}

.total-check {
    color: $aqua !important;
}

.admin .products-table {
    background-color: $white;
}

.admin .products-table .cart-container th {
    background-color: $blue;
    color: white;
    border: 1px solid #E9EBED;
}

.admin .products-table .cart-container .table-row {
    height: 60px;
}

.admin .products-table .cart-container .btn {
    padding: 9px 24px;
}

.admin .chats-table {
    background-color: $white;
}

.admin .chats-table .cart-container th {
    background-color: $white;
}

.admin .chats-table .table-row td {
    border: 1px solid $lightGray;
    padding: $paddingNormal 0;
    height: 35px;
}

.admin .chats-table .btn {
    padding: 10px 20px;
}

.admin .reports-table .btn {
    padding: 4px 24px;
}

.admin .products-table .cart-container .table-image img {
    max-height: 45px;
}

.admin .users-table, 
.admin .clients-table,
.admin .reports-table,
.admin .agencias-table {
    border: 1px solid #E9EBED;
}

.admin .reports-table {
    //max-width: 85%;
}

.admin .users-table th,
.admin .clients-table th,
.admin .reports-table th,
.admin .agencias-table th  {
    background-color: $blue;
    border: 1px solid #E9EBED;
    color: white;
}

.admin .clients-table .table-row td,
.admin .reports-table .table-row td,
.admin .agencias-table .table-row td {
    padding: 8px;
    font-size: $fontTiny;
    background: white;
}

.admin {
    .clients-table, .users-table, .agencias-table {
        .table-row {
            .edit {
                width: 12%;
            }
        }
    }

    .clients-table, .agencias-table {
        .table-row {
            .delete {
                width: 12%;
            }
        }
    }
}

.admin .clients-table .table-row .edit i,
.admin .products-table .table-row .edit i,
.admin .agencias-table .table-row .edit i {
    color: $blue;
    font-size: 1.5em;
}

.admin .clients-table .table-row .delete i,
.admin .agencias-table .table-row .delete i {
    color: $darkRed;
    font-size: 1.5em;
}

.admin .clients-table .table-row .costs i {
    color: $yellow;
    font-size: 1.5em;
}

.admin .users-table .table-row {
    cursor: inherit;
}

.admin .users-table .table-row {
    background: white;

    .edit {
        .fa-key {
            color: $yellow;
            font-size: 1.2em;
            padding-right: 15px;
        }

        .fa-pencil-alt {
            color: $blue;
            font-size: 1.2em;
            padding-right: 15px;
        }

        .fa-trash {
            color: $darkRed;
            font-size: 1.2em;
        }

        .fa-save {
            color: $green;
            font-size: 1.4em;
            padding-right: 15px;
        }

        .fa-times {
            color: $darkRed;
            font-size: 1.4em;
        }
    }
}

.admin .options-table .table-row,
.admin .prices-table .table-row {
    background: white;

    .edit {
        .fa-key {
            color: $yellow;
            font-size: 1.2em;
            padding-right: 15px;
        }

        .fa-pencil-alt {
            color: $blue;
            font-size: 1.2em;
            //padding-right: 15px;
        }

        .fa-trash {
            color: $darkRed;
            font-size: 1.2em;
        }

        .fa-save {
            color: $green;
            font-size: 1.4em;
            //padding-right: 15px;
        }

        .fa-times {
            color: $darkRed;
            font-size: 1.4em;
        }
    }
}

.admin .clients-table .edit-costos .btn {
    padding: 10px 24px;
}

.admin .clients-table .table-row-provider td {
    font-size: $fontTiny;
    padding: 10px;
    border: 1px solid $lightGray;
    padding: $paddingNormal 0;
    width: 10%;
}

.admin .clients-table .table-row-provider {
    background: white;
}

.admin .clients-table .table-row-provider .delete i {
    color: $darkRed;
    font-size: 1.5em;
}

.admin .prices-table th {
    background-color: $blue;
    border: 1px solid #E9EBED;
    color: white;
}

.admin .prices-table .table-row td {
    padding: 8px;
    font-size: $fontTiny;
    background: white;
}

.options .ot {
    width: 95%;
}

.admin .options-table th {
    background-color: $blue;
    border: 1px solid #E9EBED;
    color: white;
}

.admin .options-table .table-row td {
    padding: 8px;
    font-size: $fontTiny;
    background: white;
}

.admin .options-table .table-responsive th,
.admin .prices-table .table-responsive th {
    padding: 6px 0;
}

.admin .options-table .table-row .edit i {
    color: $blue;
    font-size: 1.3em;
}

//#00950d - verde


@media screen and (max-width: 992px) {

    .table-responsive th,
    .table-responsive td  {
        padding: $paddingMedium $paddingSemi;
    }

    .th-name {
        min-width: 300px;
    }

    .table-total-summary table {
        max-width: 50%;    
    }

}

@media screen and (max-width: 768px) {

    .table-total-summary table {
        max-width: 100%;    
    }

}

@media screen and (max-width: 576px) {

    .th-name {
        min-width: 320px;
    }    

}
