
@import "components/trejocode";
@import "components/globals/admin-header";
@import "components/globals/admin-aside";
@import "components/globals/table";
@import "components/globals/modals";
@import "components/globals/pagination";
@import "components/globals/products";

@import "components/pages/admin-login";
@import "components/pages/admin-dashboard";
@import "components/pages/admin-orders";
@import "components/pages/admin-products";
@import "components/pages/admin-users";
@import "components/pages/admin-add-product";
@import "components/pages/admin-chats";
@import "components/pages/admin-reservations";
@import "components/pages/payment";
@import "components/pages/admin-proveedores";
@import "components/pages/admin-reports";

.admin .workspace .control-content {
    background-color: $lightGray;
    max-width: 83%;
}

.admin .container {
    width: 95%;
}