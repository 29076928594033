.icon-button {
	cursor: pointer;
}

.space-11 {
	margin-left: 11px;
}

.width-80 {
	width: 80%;
}