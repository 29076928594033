.chat {
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);

    .content-chat-list {
        background-color: #fff;
        //overflow-y: auto;
        height: 519px;
        width: 55%;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;

        .chat-search {
            max-width: 100%;
            margin-bottom: 16px;
        }

        .chat-search i {
            color: #8f8f8f;
            position: absolute;
            font-size: 0.9rem;
            margin-left: 8px;
            padding-left: 16px;
        }

        .chat-search input {
            width: 100%;
            border-radius: 16px;
            margin: 0 12px;
            background-color: #d8d8d8;
            font-size: 1rem;
            font-family: $Roboto;
            font-weight: 600;
            outline: 1px solid transparent;
            padding: 8px 16px 8px 36px;
        }

        .chat-list {
            min-height: 90px;
            position: relative;
            cursor: pointer;

            .icon-delete {
                position: absolute;
                right: 10px;
                top: 10px;
                font-size: 1.2rem;
                color: #0c1b42;
            }

            .icon-nuevo {
                position: absolute;
                display: flex;
                justify-content: flex-end;
                right: 10px;
                top: 10px;
                font-size: 0.8rem;
                color: #ac0000;
            }

            .chat-details {
                padding: 0 10px 0 20px;
                border-bottom: 1px solid #ddd;

                .chat-name {
                    padding-right: 4px;
                }

                /*.chat-status i {
                    color: $green;
                }*/
            }   
        }

        .list {
            overflow-y: auto;
        }
    }

    .chat-content {
        height: 519px;
        border-left: 1px solid #d1d1d1;
        background-color: white;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;

        .header-chat {
            background-color: #0c1b42;
            height: auto;
            border-top-right-radius: 4px;

            .header-profile {
                padding: 14px 0 14px 40px;
            }

            .end-conversation {
                .btn:hover {
                    color: red;
                }
            }
        }

        .content-gral-chat {
            height: 385px;

            .content-chat {
                //width: 95%;
                //height: 417px;
                overflow-y: auto;
                padding: 0 12px;

                #chat_messages {
                    align-self: flex-start;
                }

                .message-me p {
                    max-width: 90%;
                    border-radius: 8px;
                    padding: 16px 8px;
                    background-color: rgba(62,166,214,.4);
                }

                .message-user p {
                    max-width: 90%;
                    border-radius: 8px;
                    padding: 16px 8px;
                    background-color: hsla(0,0%,73.3%,.4);
                    margin: 4px 0;
                }
            }
        }

        .bottom-controls {
            /*bottom: 28px;
            z-index: 1;*/
            padding: 12px 0;
            position: relative;
            background-color: #fff;
            box-shadow: 0 -0.1px 5px 0 #d9d9d9;
            border-bottom-right-radius: 4px;

            .container-controls {
                width: 100%;
                padding-left: 16px;

                .left {
                    width: 90%;
                }

                .left input {
                    width: 98%;
                    font-size: 1rem;
                    font-family: $Roboto;
                    font-weight: 600;
                    outline: 1px solid transparent;
                    background-color: #efefef;
                    padding: 8px 16px;
                    border-radius: 4px;
                }

                .right {
                    padding-right: 8px;

                    .icon-sent-image {
                        font-size: 1.4rem;
                    }

                    .btn-send {
                        background-color: initial;
                        padding: 0 8px;

                        .icon-sent {
                            font-size: 1.8rem;
                            margin-left: 8px;
                            color: $green;
                        }
                    }
                }
            }
        }

        .no-messages h3 {
            color: black;
        }
    }
}